<template>
  <div>
    <LoadingMessage v-if="isLoading"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error" class="m-3"></ErrorMessage>

    <div v-if="!isLoading && session">
      <PageTitle v-if="session.name" :title="session.name"></PageTitle>
      <div v-if="clientName" class="small">Client: {{ clientName }}</div>
      <div class="small text-muted">Type: {{ session.type }}</div>
      <div class="small text-muted">Date: {{ session.date | dateReadableShort }}</div>

      <SessionSummary
        :startTime="session.startedAt"
        :finishTime="session.finishedAt"
        class="my-3"
      ></SessionSummary>

      <div v-if="session.exercises.length" class="card my-3">
        <div class="card-body">
          <div class="list-group list-group-flush">
            <div
              v-for="(exercise, exerciseIndex) in session.exercises"
              :key="`key-${exerciseIndex}-${exercise.exerciseId}`"
              class="list-group-item d-flex align-items-center"
            >
              <ExerciseBadge
                v-if="!exerciseIsOfSectionType(exercise)"
                :exercise="exercise"
                :exercises="session.exercises"
                class="me-3"
              ></ExerciseBadge>

              <div class="flex-grow-1">
                <div>
                  <span
                    :class="!exerciseIsOfSectionType(exercise) ? 'fs-5' : 'fw-bold'"
                    class="me-2 lh-sm"
                  >
                    {{ exercise.name }}
                    <Tooltip :content="'Linked to a metric'">
                      <fa-icon v-if="exerciseHasMetric(exercise)" :icon="['fas', 'link']"></fa-icon>
                    </Tooltip>
                  </span>
                  <ExerciseStatus
                    v-if="!exerciseIsOfSectionType(exercise) && clientId"
                    :isCompleted="exerciseIsCompleted(exercise)"
                  ></ExerciseStatus>
                </div>

                <TextView
                  v-if="exercise.description"
                  :text="exercise.description"
                  class="smaller text-muted mt-1"
                ></TextView>

                <ExerciseSetRep
                  :sets="$options.filters.exerciseSetParsed(exercise)"
                  class="mt-1"
                ></ExerciseSetRep>

                <TextView
                  v-if="exerciseNote(exercise)"
                  :text="exerciseNote(exercise)"
                  :useQuote="true"
                  class="small mt-2"
                ></TextView>

                <ExerciseVideoList
                  v-if="exerciseVideos(exercise) && exerciseVideos(exercise).length"
                  :videos="exerciseVideos(exercise)"
                  class="small mt-2"
                ></ExerciseVideoList>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Comment :session="session" class="card my-3"></Comment>
    </div>
  </div>
</template>

<script>
import { httpGet } from '@/core/http';

export default {
  name: 'WorkoutOverview',
  components: {
    Tooltip: () => import('@/components/Tooltip'),
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    PageTitle: () => import('@/components/PageTitle'),
    SessionSummary: () => import('@/components/SessionSummary'),
    ExerciseBadge: () => import('@/components/ExerciseBadge'),
    ExerciseStatus: () => import('@/components/ExerciseStatus'),
    TextView: () => import('@/components/TextView'),
    ExerciseVideoList: () => import('@/components/ExerciseVideoList'),
    ExerciseSetRep: () => import('@/components/ExerciseSetRep'),
    Comment: () => import('@/components/comment/Comment'),
  },
  props: {
    sessionId: {
      type: Number,
      required: true,
    },
    clientId: {
      type: String,
      default: null,
    },
  },
  computed: {
    clientName() {
      if (this.session && this.session.athlete) {
        const { firstName, middleName, lastName } = this.session.athlete;
        return `${firstName} ${middleName || ''} ${lastName}`.trim();
      }
      return null;
    },
  },
  methods: {
    async fetchSession() {
      this.isLoading = true;
      try {
        const { sessionId, clientId } = this;
        const resSession = await httpGet(`/session/${sessionId}`, {
          action: 'view',
          athleteId: clientId,
        });
        const resSessionProgress = await httpGet(`/session/${sessionId}/progress`, {
          athleteId: clientId,
        });
        this.session = {
          ...resSession.data,
          startedAt: resSessionProgress.data.startedAt,
          finishedAt: resSessionProgress.data.finishedAt,
        };
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    exerciseIsOfSectionType(exercise) {
      return exercise.type === 'section';
    },
    exerciseNote(exercise) {
      const exerciseStatus = this.session.exerciseStatuses.find((status) => {
        const { exerciseId } = status;
        return exerciseId === exercise.exerciseId;
      });
      if (exerciseStatus && exerciseStatus.note && exerciseStatus.note !== '') {
        return exerciseStatus.note;
      }
      return null;
    },
    exerciseStatus(exercise) {
      const { exerciseStatuses } = this.session;
      return exerciseStatuses.find((status) => status.exerciseId === exercise.exerciseId);
    },
    exerciseVideos(exercise) {
      const { exerciseVideos } = this.session;
      if (!exerciseVideos) return null;
      const { exerciseId } = exercise;
      const videos = exerciseVideos.filter((v) => v.exerciseId === exerciseId);
      return videos;
    },
    exerciseIsCompleted(exercise) {
      const status = this.exerciseStatus(exercise);
      if (!status || status.isCompleted === null) return null;
      return !!status.isCompleted;
    },
    exerciseHasMetric(exercise) {
      const { type, metricId } = exercise;
      return (type === 'exercise' || type === 'circuit') && metricId;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      session: null,
    };
  },
  mounted() {
    this.fetchSession();
  },
};
</script>
